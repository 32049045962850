import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import SplitType from 'split-type'

import { wfSliderArrows, wfTabs, addSplideClasses, connectSplideArrows, sel, selAll } from './utils'

gsap.registerPlugin(ScrollTrigger)

export default function Home() {
  setTimeout(() => {
    const modalPromoCounter = parseInt(localStorage.getItem('modalPromo'))
    if (modalPromoCounter === -1) return

    const showEvery = 3
    const modalSuccess$ = sel('#mod-form-success')

    if (!modalPromoCounter || modalPromoCounter >= showEvery) {
      const modal$ = sel('.first-load-mod-w')
      modal$.style.display = 'block'
      gsap.to(modal$, { opacity: 1, duration: 1, ease: 'none' })
      localStorage.setItem('modalPromo', '1')
    }
    if (modalPromoCounter < showEvery) {
      localStorage.setItem('modalPromo', modalPromoCounter + 1)
    }
    // Observer to check when modalSuccess$ becomes visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          localStorage.setItem('modalPromo', '-1')
          observer.disconnect()
        }
      })
    })

    observer.observe(modalSuccess$)
  }, 1000)
  /*
  const h1$ = sel('.hero__info h1')
  const h1St = new SplitType(h1$, { types: 'lines, chars', lineClass: 'st-overflow' })
  
  gsap
    .timeline()
    .from(h1St.chars, { duration: 1, y: 100, opacity: 0, stagger: 0.03, ease: 'power4.out' })
    .from('.hero__p', { opacity: 0, duration: 0.5 }, '>-0.9')
    .from('.hero__info .btn-w', { opacity: 0, duration: 0.5 }, '>-0.3')
    */
  ;(() => {
    const prefix = 'vision'
    const el = '.' + prefix + '__slider'
    if (!sel(el)) return
    addSplideClasses(el)

    const slider = new Splide(el, {
      arrows: false,
      pagination: false,
      gap: '4rem',
      perPage: 2,
      perMove: 1,
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      breakpoints: {
        991: {
          perPage: 2,
          gap: '2rem',
          // perMove: 2,
        },
        478: {
          perPage: 1,
          // perMove: 1,
        },
      },
    }).mount()
    connectSplideArrows(slider, prefix + '__arrows')
  })()

  const planCard$a = selAll('.plan__item-c')
  gsap.from(planCard$a, { y: 36, opacity: 0, duration: 2, ease: 'power3.out', stagger: 0.2, scrollTrigger: { trigger: '.plan__item-c', start: 'top 75%' } })
}
