import { lenis } from './lenis'
import { initObserver, observeVisibleHidden, onDomReady, sel, selAll } from './utils'

export default function Intake() {
  onDomReady(() => {
    sel('.intake__steps__item.is--active')?.classList.remove('is--active')
    sel('.intake__steps__item')?.classList.add('is--active')
    const sideSteps$a = selAll('.intake__steps__item')
    const dates$a = selAll('input.is--date')
    let currentStep = 1
    let dateSelected

    dates$a.forEach((dateInput) => {
      // almost all of it just to make it work with zoho
      let isActive = false
      // dateInput.setAttribute('pattern', `\\d{2}\/\\d{2}\/\\d{4}`)
      const rows$ = dateInput.closest('.form__rows')
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'text'
      hiddenInput.setAttribute('hidden', '')
      hiddenInput.setAttribute('date-active', 'false')

      const name = dateInput.getAttribute('name')
      // change instead of deleting to make make it work with superform
      dateInput.setAttribute('name', name + '-old')
      if (dateInput.hasAttribute('readonly')) dateInput.removeAttribute('readonly')

      // Set the id and name attributes of the date inputs to the previous name
      dateInput.setAttribute('id', name)
      hiddenInput.setAttribute('name', name)
      dateInput.parentNode.append(hiddenInput)

      dateInput.addEventListener('pointerdown', () => {
        // to address the delay before is--invalid is removed
        dateInput.classList.add('is--init')
        console.log('start')
        setTimeout(() => {
          const mod = sel('.datepicker-container:not(.datepicker-hide)')
          if (mod?.hasAttribute('data-name') || !mod) return
          console.log('dd register')
          mod.setAttribute('data-name', name)
          console.log('click')
          console.dir(mod)

          mod.addEventListener('click', () => {
            console.log('dd')
            if (!mod?.hasAttribute('tabindex')) mod?.setAttribute('tabindex', '0')

            mod?.focus()
            setTimeout(() => {
              updateDate()
            }, 800)
          })
          mod.addEventListener(
            'blur',
            () => {
              console.log('dd blur')
              isActive = true
              hiddenInput.setAttribute('date-active', 'true')
            },
            { once: true }
          )
        }, 500)
      })

      dateInput.addEventListener('blur', () => {
        console.log('date blur')
        updateDate()
      })
      dateInput.addEventListener('input', () => {
        console.log('input')
        updateDate()
      })

      function updateDate() {
        console.log('handleDateInput', dateSelected, dateInput.value)

        if (dateSelected && hiddenInput.value && dateSelected === dateInput.value) return console.log('same date')
        if (dateInput.value === '') {
          hiddenInput.value = ''
          return console.log('empty date')
        }
        const date = new Date(dateInput.value)
        if (isNaN(date)) return console.log('invalid date')

        dateSelected = dateInput.value
        console.log('up', date)

        const day = ('0' + date.getDate()).slice(-2)
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const month = months[date.getMonth()]
        const year = date.getFullYear()

        const formattedDate = `${day}-${month}-${year}`

        // Update the hidden input value
        hiddenInput.value = formattedDate
        // rows$.querySelector('select, textarea, input:not([data-toggle="datepicker"], [hidden])')?.dispatchEvent(new Event('input', { bubbles: true }))
        dateInput.dispatchEvent(new Event('input', { bubbles: true }))
        dateInput.classList.remove('is--init')
      }
    })

    const steps$a = selAll('[sf-step]')
    if (steps$a) {
      steps$a.forEach((step) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.target.style.display === 'flex') {
              const step$ = entry.target
              steps$a.forEach((el) => {
                el.classList.remove('is--active')
              })
              step$.classList.add('is--active')

              const step = step$.getAttribute('data-step').split('-')[0]
              if (step !== currentStep) {
                sideSteps$a[currentStep - 1].classList.remove('is--active')
                sideSteps$a[step - 1].classList.add('is--active')
                currentStep = step
              }
            }
          })
        })
        observer.observe(step)

        const formFieldWrapper$a = step.querySelectorAll('.form_field-wrapper')
        // console.log(formFieldWrapper$a.length)
        formFieldWrapper$a.forEach((inputW$) => {
          const hasRequiredInput = inputW$.querySelector('[sf-radio-required]:not([hidden]), [req]:not([hidden], [req=""]), [required]:not([hidden])')
          const isDateInput = inputW$.classList.contains('.is--date')
          if (!hasRequiredInput) return

          const input$ = inputW$.querySelector('input:not([hidden]), select, textarea')

          // console.log('input', input$)

          // if (input$.value === '') {
          //   input$.classList.add('is--invalid')
          // }
          const type = input$.type
          input$.addEventListener('input', function (e) {
            const type = e.target.type
            console.log('input', type)
            const value = e.target.value
            let _valid = null
            const rgNotEmpty = /^(?!\s*$).+/
            if (type === 'email') {
              _valid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$/
            }
            if (type === 'tel') {
              _valid = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
            }
            if (type === 'select-one') {
              _valid = rgNotEmpty
            }
            if (type === 'text') {
              const dataType = e.target.getAttribute('data-type')
              if (dataType === '') {
                _valid = rgNotEmpty
              }
              if (dataType === 'name') {
                _valid = /^[A-Za-z\s]+$/
              }
              if (dataType === 'address') {
                _valid = /^\s*\S+(?:\s+\S+){2}/
              }
              if (e.target.classList.contains('is--date')) {
                const today = new Date()
                const inputDate = new Date(value)
                if (inputDate >= today) {
                  console.log('timetraveler detected')

                  input$.classList.add('is--invalid')
                  // return
                } else {
                  _valid = /^\d{2}\/\d{2}\/\d{4}$/
                }
              }
            }
            if (!_valid) return console.log('wrong validation type in the input:', e.target)

            const valid = _valid?.test(value)
            // el.classList.toggle('is--valid', valid)
            if (!valid) {
              // console.log('invalid', e.target)
              input$.classList.add('is--invalid')
            } else {
              console.log('valid', e.target)

              input$.classList.remove('is--invalid')
            }
            //
          })

          const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  if (type === 'radio' || type === 'checkbox') {
                    // console.log('inter', input$)
                    const inputW$ = input$.closest('.form_field-wrapper')
                    function validateCheckRadio() {
                      if (type === 'checkbox') {
                        const input$a = inputW$.querySelectorAll('input[req="1"]')
                        const anyUnchecked = Array.from(input$a).some((input) => !input.checked)
                        if (anyUnchecked) return inputW$.classList.add('is--invalid')
                      }
                      inputW$.classList.remove('is--invalid')
                    }
                    validateCheckRadio()
                    if (!inputW$.classList.contains('is--init')) inputW$.classList.add('is--invalid', 'is--init')
                    inputW$.onchange = () => validateCheckRadio()
                    // input$.classList.add('is--init')
                  }
                  if (input$.value === '') {
                    input$.classList.add('is--invalid')
                  }
                } else {
                  if (type === 'radio' || type === 'checkbox') {
                    const inputW$ = input$.closest('.form_field-wrapper')
                    console.log('not intersecting', inputW$)
                    inputW$.classList.remove('is--invalid')
                  }
                  input$.classList.remove('is--invalid')
                }
              })
            },
            { root: null, threshold: 0 }
          )
          observer.observe(input$)

          //
        })
      })
    }

    selAll('[sf-goto="next"]').forEach((btn) => {
      btn.addEventListener('click', () => {
        lenis.scrollTo(0)
      })
    })
    // Consent - last step
    const contacts$ = selAll('.form__contact__item')
    let showPersons = 1
    const totalPersons = contacts$.length
    contacts$.forEach((inputW) => {
      const person = inputW.dataset.person
      const checkboxW$ = sel(`.form__consent__behalf>*[data-person="${person}"]`)
      if (person !== '1') {
        inputW.classList.add('hide')
        // checkboxW$.classList.add('hide')
      }
      // const inputName$ = inputW.querySelector('input.is--name')
      // inputName$.addEventListener('change', function () {
      //   const name = this.value
      //   const checkboxName$ = checkboxW$.querySelector('label.is--main')
      //   checkboxName$.textContent = name
      // })
    })

    const addPerson$ = sel('.form__consent__add-a')
    addPerson$.addEventListener('click', function () {
      showPersons++
      sel(`.form__contact__item[data-person="${showPersons}"]`).classList.remove('hide')
      // sel(`.form__consent__behalf>*[data-person="${showPersons}"]`).classList.remove('hide')
      // console.log(showPersons, totalPersons)
      if (showPersons >= totalPersons) {
        this.classList.add('hide')
      }
    })

    //
    /*
  const steps$a = selAll('[data-form="step"]')
  // Create a MutationObserver instance
  const observer = new MutationObserver((mutationsList) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        const element = mutation.target
        if (!element.getAttribute('style')) {
          selAll('.is--active[data-form="step"]').forEach((step) => {
            step.classList.remove('is--active')
          })
          element.classList.add('is--active')
          const step = element.getAttribute('data-step').split('-')[0]
          if (step !== currentStep) {
            sideSteps$a[currentStep - 1].classList.remove('is--active')
            sideSteps$a[step - 1].classList.add('is--active')
            currentStep = step
          }
        }
      }
    }
  })

  // Start observing the `style` attribute for each element in `steps$a`
  steps$a.forEach((step) => {
    observer.observe(step, { attributes: true })
  })

  */
  })
}
