import { onDomReady, sel, selAll } from './utils'

export default function Form() {
  sel('#btn-next').onclick = () => {
    sel('.form__right-arrow').click()
  }
  sel('#btn-prev').onclick = () => {
    sel('.form__left-arrow').click()
  }

  let formVars = { FirstName: '', LastName: '', Email: '', Dropdown: '', SingleLine: '', SingleLine1: '', international_PhoneNumber_countrycode: '' }
  const next$ = sel('#btn-next')

  for (const key in formVars) {
    const input = sel('#' + key)
    // console.log(input, key)

    input?.addEventListener('input', function (e) {
      formVars[key] = e.target.value || ''

      const yep =
        Object.values(formVars).every((value) => value !== '') &&
        formVars.Email.includes('@') &&
        formVars.international_PhoneNumber_countrycode.length > 5 &&
        formVars.Dropdown !== '-Select-'
      if (yep) next$.classList.remove('is--off')
      else next$.classList.add('is--off')
    })
  }
}
