import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import SplitType from 'split-type'

import { wfSliderArrows, wfTabs, addSplideClasses, connectSplideArrows, sel, selAll } from './utils'

// gsap.registerPlugin(ScrollTrigger)

export default function Home() {
  function sliderInit() {
    const name = 'partner-all'
    addSplideClasses(name + '__slider')
    const splide = new Splide('.' + name + '__slider', {
      autoplay: true,
      // rewind: true,
      type: 'loop',
      pagination: false,
      arrows: false,
      speed: 1500,
      interval: 3000,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    })

    addSplideClasses(name + '__bg-slider')
    const thumbnails = new Splide('.' + name + '__bg-slider', {
      gap: '1rem',
      perPage: 1,
      type: 'fade',
      pagination: false,
      arrows: false,
      speed: 2000,
    })
    splide.sync(thumbnails)
    splide.mount()
    thumbnails.mount()
    connectSplideArrows(splide, 'slider__arrows')
  }
  sliderInit()
}
